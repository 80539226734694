const colors = {
  darkTeal: "#00353d",
  pastelRed: "#e86752",
  blueyGreen: "#2bb673",
  darkPeach: "#e57152",
  veryLightBrown: "#cacb9f",
  offWhite: "#fefce8",
  dullYellow: "#eae463",
  veryLightGreen: "#d6f0db",
  white: "#fff",
}

const alias = {
  text: colors.darkTeal,
  background: colors.offWhite,
  primary: colors.pastelRed,
  secondary: colors.darkTeal,
  accent: colors.blueyGreen,
}

export default { ...colors, ...alias }
