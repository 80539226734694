export default [
  {
    label: "Université McGill",
    value: "McGill",
  },
  {
    label: "Campus en Outaouais de l’Université McGill",
    value: "UMGCO",
  },
  {
    label: "Université de Montréal",
    value: "UDM",
  },
  {
    label: "Campus en Mauricie de l’Université de Montréal",
    value: "UDMCM",
  },
  {
    label: "Université de Sherbrooke",
    value: "UDS",
  },
  {
    label: "Campus au Saguenay de l’Université de Sherbrooke",
    value: "UDSCS",
  },
  {
    label: "Université Laval",
    value: "ULAVAL",
  },
  {
    label: "Non-applicable",
    value: "none",
  },
]
