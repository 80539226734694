// vendors
import { css } from "@emotion/core"

import media from "./media-query"
import { between } from "polished"

export const left = (type = "margin") => {
  if (type === "margin") {
    return {
      marginLeft: "30px",
      "@media screen and (min-width: 1024px)": {
        marginLeft: between("30px", "240px", "1024px", "1920px"),
      },
      "@media screen and (min-width: 1920px)": {
        marginLeft: `calc(50vw - ${1920 / 2}px)`,
      },
    }
  }

  if (type === "padding") {
    return {
      paddingLeft: "30px",
      "@media screen and (min-width: 1024px)": {
        paddingLeft: between("30px", "240px", "1024px", "1920px"),
      },
      "@media screen and (min-width: 1920px)": {
        paddingLeft: `calc(50vw - ${1920 / 2}px)`,
      },
    }
  }
}

export const right = (type = "margin") => {
  if (type === "margin") {
    return {
      marginRight: "30px",
      "@media screen and (min-width: 1024px)": {
        marginRight: between("30px", "240px", "1024px", "1920px"),
      },
      "@media screen and (min-width: 1920px)": {
        marginRight: `calc(50vw - ${1920 / 2}px)`,
      },
    }
  }

  if (type === "padding") {
    return {
      paddingRight: "30px",
      "@media screen and (min-width: 1024px)": {
        paddingRight: between("30px", "240px", "1024px", "1920px"),
      },
      "@media screen and (min-width: 1920px)": {
        paddingRight: `calc(50vw - ${1920 / 2}px)`,
      },
    }
  }
}

export const paddingLeft = () => {
  return css`
    padding-left: ${between("0px", "45px", "320px", "1920px")};

    ${media.greaterThen(1920)} {
      padding-left: 45px;
    }
  `
}

export const paddingRight = () => {
  return css`
    padding-right: ${between("0px", "45px", "320px", "1920px")};

    ${media.greaterThen(1920)} {
      padding-right: 45px;
    }
  `
}

export const bolt = (type = "margin") => {
  if (type === "margin") {
    return {
      maxWidth: "1440px",
      marginLeft: "30px",
      marginRight: "30px",
      "@media screen and (min-width: 1024px)": {
        marginLeft: between("30px", "240px", "1024px", "1920px"),
        marginRight: between("30px", "240px", "1024px", "1920px"),
      },
      "@media screen and (min-width: 1920px)": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    }
  }

  if (type === "padding") {
    return {
      paddingLeft: "30px",
      paddingRight: "30px",
      "@media screen and (min-width: 1024px)": {
        paddingLeft: between("30px", "240px", "1024px", "1920px"),
        paddingRight: between("30px", "240px", "1024px", "1920px"),
      },
      "@media screen and (min-width: 1920px)": {
        paddingLeft: "calc(50vw - 720px)",
        paddingRight: "calc(50vw - 720px)",
      },
    }
  }
}

export default { bolt, left, right, paddingLeft, paddingRight }
