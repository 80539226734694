// vendors
import React from "react"
import { css } from "@emotion/core"
import { fontSizes, colors, transition } from "../../styles/variables"

const Checkbox = ({
  label,
  checked,
  onChange,
  onBlur,
  name,
  value,
  ...props
}) => (
  <label
    {...props}
    css={css`
      display: flex;
      align-items: flex-start;
      line-height: ${fontSizes[3] / fontSizes[1]};
      margin-bottom: 1em;
      cursor: pointer;

      input {
        margin-top: 0.1em;
        margin-right: 1ch;
      }
    `}
  >
    <input
      type="checkbox"
      checked={checked}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      css={css`
        appearance: none;
        display: inline-block;
        background-color: ${colors.white};
        height: 18px;
        width: 18px;
        position: relative;
        border-radius: 3px;
        flex-shrink: 0;
        flex-basis: 18px;

        &:after {
          display: block;
          position: absolute;
          border-radius: 1px;
          content: "";
          width: 12px;
          height: 12px;
          top: 3px;
          left: 3px;
          background-color: ${colors.blueyGreen};
          transform: scale(0);
          transition: transform ${transition.speed.fast}
            ${transition.curve.default};
        }

        &:checked:after {
          transform: scale(1);
        }
      `}
    />

    {label}
  </label>
)

export default Checkbox
