import React from "react"

export default [
  { label: "Prémédecine", value: "Prémédecine" },
  {
    label: (
      <>
        1<sup>er</sup> cycle
      </>
    ),
    value: "1er",
  },
  {
    label: (
      <>
        2<sup>e</sup> cycle
      </>
    ),
    value: "2e",
  },
  {
    label: (
      <>
        3<sup>e</sup> cycle
      </>
    ),
    value: "3e",
  },
  { label: "Non-applicable", value: "none" },
]
