const fontSizes = [15, 18, 20, 24, 30, 36, 44, 52, 68, 84]
// 0 = 15
// 1 = 18
// 2 = 20
// 3 = 24
// 4 = 30
// 5 = 36
// 6 = 44
// 7 = 52
// 8 = 68
// 9 = 84

fontSizes.body = fontSizes[0]

fontSizes.breakpoint = 1024

export default fontSizes
