// vendors
import React from "react"
import { css } from "@emotion/core"
import InputMask from "react-input-mask"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import Input from "../../../components/Input"

import { fontSizes } from "../../../styles/variables"
import mediaQuery from "../../../utils/media-query"
import Button from "../../../components/Button/Button"

import { FormGroup, Label, ErrorMessage } from "./../style"
import TextArea from "../../../components/TextArea"
import SegementedControls from "../../../components/SegementedControls/SegementedControls"
import serializeData from "../../../utils/serializeData"

const formValidateSchema = Yup.object().shape({
  firstname: Yup.string().required("Information incomplète"),
  lastname: Yup.string().required("Information incomplète"),
  message: Yup.string().required("Information incomplète"),
  email: Yup.string()
    .email("Adresse courriel invalide")
    .required("Information incomplète"),
})

const GeneralForm = ({ breakpoint, onSubmited = () => {}, hidden }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      "mauticform[formId]": 3,
      "mauticform[firstname]": values.firstname,
      "mauticform[lastname]": values.lastname,
      "mauticform[telephone]": values.telephone,
      "mauticform[email]": values.email,
      "mauticform[status]": values.status,
      "mauticform[f_message]": values.message,
    }

    fetch("https://mautic.equipesarros.ca/form/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: serializeData(data),
    })
      .then(() => {
        onSubmited()
      })
      .catch((error) => alert(error))

    setSubmitting(false)
  }

  return (
    <div hidden={hidden}>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          telephone: "",
          email: "",
          message: "",
          status: "",
          "bot-field": "",
        }}
        validationSchema={formValidateSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form
            method="post"
            css={css`
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              grid-column-gap: 36px;

              ${mediaQuery.greaterThen(breakpoint)} {
                grid-template-columns: repeat(2, 1fr);
              }
            `}
          >
            <input
              type="hidden"
              name="form-name"
              value="Demande d'informations générales"
            />

            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>

            {/* Prénom */}
            <FormGroup
              css={css`
                grid-column: 1 / span 1;
                margin-bottom: ${fontSizes[5] / fontSizes[1]}em;
              `}
            >
              <Label htmlFor="input-firstname">Prénom</Label>
              <Input
                type="text"
                id="input-firstname"
                name="firstname"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstname && touched.firstname}
              />
              <ErrorMessage>
                {errors.firstname && touched.firstname && (
                  <p>{errors.firstname}</p>
                )}
              </ErrorMessage>
            </FormGroup>

            {/* Nom */}
            <FormGroup
              css={css`
                grid-column: span 1;
              `}
            >
              <Label htmlFor="input-lastname">Nom</Label>

              <Input
                type="text"
                id="input-lastname"
                name="lastname"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastname && touched.lastname}
              />

              <ErrorMessage>
                {errors.lastname && touched.lastname && (
                  <p>{errors.lastname}</p>
                )}
              </ErrorMessage>
            </FormGroup>

            {/* Courriel */}
            <FormGroup
              css={css`
                grid-column: span 1;
              `}
            >
              <Label htmlFor="input-email">Courriel</Label>
              <Input
                type="email"
                id="input-email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
              />

              <ErrorMessage>
                {errors.email && touched.email && <p>{errors.email}</p>}
              </ErrorMessage>
            </FormGroup>

            {/* Téléphone */}
            <FormGroup
              css={css`
                grid-column: span 1;
              `}
            >
              <Label htmlFor="input-telephone">Téléphone</Label>

              <InputMask
                mask="999-999-9999"
                value={values.telephone}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {(inputProps) => (
                  <Input
                    type="tel"
                    {...inputProps}
                    id="input-telephone"
                    name="telephone"
                    placeholder="123-123-1234"
                    error={errors.telephone && touched.telephone}
                    css={css`
                      width: auto;
                      display: inline;
                    `}
                  />
                )}
              </InputMask>

              <ErrorMessage>
                {errors.telephone && touched.telephone && (
                  <p>{errors.telephone}</p>
                )}
              </ErrorMessage>
            </FormGroup>

            {/* Niveau */}
            <FormGroup
              css={css`
                grid-column: span 1;

                ${mediaQuery.greaterThen(breakpoint)} {
                  grid-column: span 2;
                }
              `}
            >
              <Label htmlFor="input-status">Statut</Label>

              <SegementedControls
                type="text"
                id="input-status"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.status && touched.status}
                breakpoint="765"
                options={[
                  { label: "Étudiant", value: "etudiant" },
                  { label: "Externe", value: "externe" },
                  { label: "Résident", value: "resident" },
                  { label: "Médecin pratiquant", value: "pratiquant" },
                  { label: "Autre", value: "autre" },
                ]}
              />

              <ErrorMessage>
                {errors.status && touched.status && <p>{errors.status}</p>}
              </ErrorMessage>
            </FormGroup>

            {/* message */}
            <FormGroup
              css={css`
                grid-column: span 1;

                ${mediaQuery.greaterThen(breakpoint)} {
                  grid-column: span 2;
                }
              `}
            >
              <Label htmlFor="textArea-message">Détails de votre demande</Label>
              <TextArea
                id="textArea-message"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.message && touched.message}
              />

              <ErrorMessage>
                {errors.message && touched.message && <p>{errors.message}</p>}
              </ErrorMessage>
            </FormGroup>

            <Button full secondary type="submit" disabled={isSubmitting}>
              Envoyer ma demande
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default GeneralForm
