// vendors
import React from "react"

const LogoFacebook = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" {...props}>
      <path d="M448,48V400a48,48,0,0,1-48,48H314.7V270.8h60.6l8.7-67.6H314.7V160c0-19.6,5.4-32.9,33.5-32.9H384V66.7A481.31,481.31,0,0,0,331.8,64c-51.6,0-87,31.5-87,89.4v49.9H184v67.6h60.9V448H48A48,48,0,0,1,0,400V48A48,48,0,0,1,48,0H400A48,48,0,0,1,448,48Z" />
    </svg>
  )
}

export default LogoFacebook
