// vendors
import React from "react"
import { css } from "@emotion/core"
import { colors, zIndices, transition, fontSizes } from "../../styles/variables"
import VisuallyHidden from "@reach/visually-hidden"
// import FocusLock from "react-focus-lock"
import { RemoveScroll } from "react-remove-scroll"
import Portal from "../Portal"

const Modal = ({ handleClose, isOpen, usePortal, children }) => {
  const ModalContent = () => (
    <RemoveScroll enabled={isOpen}>
      <div
        css={css`
          position: fixed;
          width: 100vw;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: ${zIndices.modalBackdrop};
          pointer-events: none;
          overflow: scroll;
          ${isOpen &&
          css`
            pointer-events: auto;
          `}
        `}
      >
        <div
          css={css`
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: ${colors.white};
            z-index: ${zIndices.modalBackdrop};
            opacity: 0;
            pointer-events: none;
            transition: opacity ${transition.speed.default}
              ${transition.curve.default};

            ${isOpen &&
            css`
              pointer-events: auto;
              opacity: 0.8;
            `}
          `}
        />

        {isOpen && (
          <dialog
            open={isOpen}
            css={css`
              margin: ${fontSizes[9] / fontSizes[0]}em auto;
              z-index: ${zIndices.modal};
              position: relative;
              appearance: none;
              border: none;
              background: ${colors.darkTeal};
              color: ${colors.offWhite};
              width: 100%;
              padding: 0;
              max-width: 1040px;
              display: flex;
              flex-flow: column;
            `}
          >
            <div
              css={css`
                order: 2;
              `}
            >
              {children}
            </div>

            <div
              css={css`
                text-align: right;
                padding: ${fontSizes[5] / fontSizes[1]}em;
              `}
            >
              <button
                onClick={() => handleClose()}
                css={css`
                  appearance: none;
                  border: none;
                  background: none;
                  cursor: pointer;
                `}
              >
                <VisuallyHidden>fermer le formulaire</VisuallyHidden>

                <span
                  aria-hidden
                  css={css`
                    color: ${colors.offWhite};
                    font-weight: 900;
                    font-size: ${fontSizes[5] / fontSizes[0]}em;
                  `}
                >
                  x
                </span>
              </button>
            </div>
          </dialog>
        )}
      </div>
    </RemoveScroll>
  )

  return (
    <>
      {usePortal && isOpen ? (
        <Portal>
          <ModalContent />
        </Portal>
      ) : (
        <ModalContent />
      )}
    </>
  )
}

export default Modal
