// vendors
import React from "react"
import { css } from "@emotion/core"
import { fontSizes, colors } from "../../styles/variables"

const TextArea = ({ error, children, ...props }) => (
  <textarea
    css={css`
      display: inline-block;
      appearance: none;
      padding: ${20 / fontSizes[1]}em ${40 / fontSizes[1]}em;
      border-radius: 34.5px;
      width: 100%;
      min-height: 150px;
      box-sizing: border-box;
      margin-bottom: 1em;
      border: 2px solid transparent;

      ${error &&
      css`
        border-color: ${colors.pastelRed};

        :after {
          display: block;
          border-radius: 50%;
          background-color: ${colors.pastelRed};
          width: 10px;
          height: 10px;
          content: "";
        }
      `}
    `}
    {...props}
  >
    {children}
  </textarea>
)

export default TextArea
