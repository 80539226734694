import { useState, useEffect } from "react"

export default (breakpoint = 1024) => {
  if (typeof window === "undefined") return false

  const evaluation = () =>
    window.matchMedia(`(min-width: ${breakpoint}px)`).matches

  const [matches, setMatches] = useState(evaluation())

  useEffect(() => {
    const handleResize = () => setMatches(evaluation())
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return matches
}
