// vendors
import styled from "@emotion/styled"
import { fontSizes, colors } from "../../styles/variables"

export const Label = styled.label`
  display: block;
  font-size: ${fontSizes[4] / fontSizes[1]}em;
  font-weight: bold;
  margin-bottom: ${fontSizes[0] / fontSizes[1]}em;
  & + [Input] {
    margin-top: ${fontSizes[2] / fontSizes[1]}em;
  }
`

export const FormGroup = styled.div`
  margin-bottom: ${fontSizes[5] / fontSizes[1]}em;
`

export const ErrorMessage = styled.div`
  height: ${fontSizes[0] / fontSizes[1]}em;

  p {
    color: ${colors.pastelRed} !important;
    margin-left: ${40 / fontSizes[1]}em;
    margin-bottom: 0;
  }
`
