// vendors
import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"

// components
import Header from "../Header"

// styles
import "normalize.css"
import "react-datepicker/dist/react-datepicker.css"
import globalStyle from "../../styles/global"
import SiteFooter from "../SiteFooter/SiteFooter"
import ContactForm from "../../views/ContactForm"

const Layout = ({ children }) => (
  <>
    <Global styles={globalStyle} />

    <Header />

    <main>{children}</main>

    <SiteFooter />

    <ContactForm />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
