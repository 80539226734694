// vendors
import React from "react"

const PinPoint = ({ color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.99 36.33" {...props}>
    <g fill={color}>
      <path d="M15,0A15,15,0,0,0,0,15c0,5.4,4.08,10.91,7.51,14.59A60.36,60.36,0,0,0,15,36.33a60.09,60.09,0,0,0,7.48-6.71C25.91,26,30,20.43,30,15A15,15,0,0,0,15,0Zm.2,29.49-.2.19-.2-.19c-4.12-3.75-9-9.41-9-15.13a9.25,9.25,0,0,1,11-9.07A9.31,9.31,0,0,1,22.9,9.58C26.14,15,23.41,22,15.2,29.49Z" />
      <path d="M15.75,11.09A4.11,4.11,0,0,0,15,11a3.92,3.92,0,0,0-3.83,4.68,4,4,0,0,0,3.4,3.12,3.92,3.92,0,0,0,4.28-4.65A4,4,0,0,0,15.75,11.09Z" />
    </g>
  </svg>
)

export default PinPoint
