// vendors
import React from "react"
import { css } from "@emotion/core"
import { fontSizes, colors, transition } from "../../styles/variables"
import { mix } from "polished"
import mediaQuery from "../../utils/media-query"

const SegementedControls = ({
  name,
  options,
  value,
  onChange,
  onBlur,
  error,
  breakpoint,
  wrap,
  withBorder,
  withBackgroundColor,
  ...props
}) => {
  return (
    <div {...props}>
      <div
        css={css`
          display: block;
          border: 2px solid ${withBorder ? colors.darkTeal : "transparent"};
          border-radius: 34.5px;
          overflow: hidden;

          ${error &&
          css`
            border-color: ${colors.darkPeach};
          `}
        `}
      >
        <div
          css={css`
            display: flex;
            margin: -0.5px;

            flex-wrap: ${wrap ? "wrap" : "nowrap"};

            label {
              flex-basis: ${wrap ? "calc(50% - 1px)" : "auto"};
            }

            ${mediaQuery.lessThen(breakpoint)} {
              flex-flow: column;
            }
          `}
        >
          {options.map((option) => (
            <label
              css={css`
                background-color: ${withBackgroundColor
                  ? colors.veryLightGreen
                  : colors.white};
                color: ${colors.darkTeal};
                padding: ${20 / fontSizes[1]}em ${40 / fontSizes[1]}em;
                box-sizing: border-box;
                text-align: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                margin: 0.5px;
                transition: all ${transition.speed.default}
                  ${transition.curve.default};

                :hover {
                  background-color: ${mix(
                    0.75,
                    colors.white,
                    colors.blueyGreen
                  )};
                }

                input {
                  appearance: none;
                }

                ${value === option.value &&
                css`
                  background-color: ${colors.blueyGreen};
                  color: ${colors.white};

                  :hover {
                    background-color: ${colors.blueyGreen};
                  }
                `}
              `}
            >
              <input
                type="radio"
                value={option.value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                checked={value === option.value}
              />
              {option.label}
            </label>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SegementedControls
