// vendors
import React from "react"
import { css } from "@emotion/core"
import { fontSizes, colors } from "../../styles/variables"

const Input = ({
  type = "text",
  error,
  withBorder,
  withBackgroundColor,
  ...props
}) => {
  return (
    <input
      css={css`
        display: inline-block;
        appearance: none;
        padding: ${20 / fontSizes[1]}em ${40 / fontSizes[1]}em;
        border-radius: 34.5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 1em;
        border: 2px solid ${withBorder ? colors.darkTeal : "transparent"};
        background-color: ${withBackgroundColor && colors.veryLightGreen};

        /* :focus {
          outline: 0;
        } */

        ${error &&
        css`
          border-color: ${colors.pastelRed};

          :after {
            display: block;
            border-radius: 50%;
            background-color: ${colors.pastelRed};
            width: 10px;
            height: 10px;
            content: "";
          }
        `}
      `}
      type={type}
      {...props}
    />
  )
}

export default Input
