// vendors
import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

// styles
import { fonts, fontSizes, colors, transition } from "../../styles/variables"

const components = {
  link: Link,
  a: ({ children, ...rest }) => <a {...rest}>{children}</a>,
  href: ({ children, ...rest }) => <a {...rest}>{children}</a>,
  button: ({ children, ...rest }) => <button {...rest}>{children}</button>,
  label: ({ children, ...rest }) => <label {...rest}>{children}</label>,
}

const Wrapper = styled.span`
  display: inline;
  text-align: left;
`

const IconWrapper = styled.span`
  display: block;
  height: 40px;
  width: 40px;
`

const Arrow = styled.span`
  transition: transform ${transition.speed.default} ${transition.curve.default};
  display: inline-block;
  margin-left: 0.5ch;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const Button = ({
  children,
  tag,
  to,
  renderIcon,
  secondary,
  dark,
  full,
  parentBackgroundColor,
  hoverColor,
  disabled,
  ...rest
}) => {
  const Tag = components[tag || "button"]

  const props = {
    to: tag === "link" ? to : undefined,
    href: tag === `href` || tag === `a` ? to : undefined,
    ...rest,
  }

  const buttonStyles = {
    default: {
      alignItems: `right`,
      borderWidth: 3,
      borderStyle: `solid`,
      borderRadius: "35px / 50%",
      cursor: disabled ? "not-allowed" : "pointer",
      fontSize: fontSizes[2],
      fontFamily: fonts.accent,
      lineHeight: 1.08,
      fontWeight: 700,
      flexShrink: 0,
      display: `inline-flex`,
      alignItems: "center",
      gap: "1rem",
      boxSizing: "border-box",
      minHeight: "67px",
      maxWidth: "30ch",
      padding: "0 35px",
      textAlign: "center",
      textDecoration: `none`,
      textRendering: "optimizeLegibility",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      transition: `all ${transition.speed.default}`,
      [`@media (min-width: ${fontSizes.breakpoint}px)`]: {
        fontSize: fontSizes[3],
        "p + &, table + &": {
          margin: `${fontSizes[7]}px 0`,
        },
      },
      ...(!disabled && {
        ":hover, :focus": {
          color: hoverColor || colors.secondary,
          borderColor: hoverColor || colors.secondary,
          [Arrow]: {
            transform: "translateX(10px)",
          },
        },
        ":focus": {
          outline: 0,
          boxShadow: `0 0 0 10 ${colors.secondary}`,
        },
      }),
      "p + &, table + &": {
        margin: `${fontSizes[8]}px 0`,
      },
    },
    full: {
      ":hover, :focus": {
        backgroundColor: colors.secondary,
        borderColor: colors.secondary,
        [Arrow]: {
          transform: "translateX(10px)",
        },
      },
    },
    primary: {
      default: {
        backgroundColor: "transparent",
        color: colors.primary,
        borderColor: colors.primary,
      },
      full: {
        backgroundColor: colors.primary,
        color: parentBackgroundColor || "white",
      },
    },
    secondary: {
      default: {
        backgroundColor: "transparent",
        borderColor: colors.accent,
        color: colors.accent,
      },
      full: {
        background: colors.accent,
        color: parentBackgroundColor || "white",
      },
    },
    dark: {
      default: {
        backgroundColor: "transparent",
        borderColor: colors.secondary,
        color: colors.secondary,
      },
      full: {
        background: colors.secondary,
        color: parentBackgroundColor || "white",
      },
    },
  }

  const style = {
    "&&": {
      ...buttonStyles.default,
      ...(!secondary && !dark && buttonStyles.primary.default),
      ...(!secondary && !dark && full && buttonStyles.primary.full),
      ...(secondary && buttonStyles.secondary.default),
      ...(secondary && full && buttonStyles.secondary.full),
      ...(dark && buttonStyles.dark.default),
      ...(dark && full && buttonStyles.dark.full),
      ...(full && buttonStyles.full),
      ...(disabled && { opacity: ".3" }),
    },
  }

  return (
    <Tag {...props} disabled={disabled} css={style}>
      {renderIcon && <IconWrapper>{renderIcon}</IconWrapper>}
      <Wrapper>
        {children}&nbsp;<Arrow>→</Arrow>
      </Wrapper>
    </Tag>
  )
}

export default Button
