// vendors
import React, { useState } from "react"
import { css } from "@emotion/core"
import Modal from "../components/Modal/Modal"
import {
  fontSizes,
  colors,
  fonts,
  transition,
  zIndices,
} from "../styles/variables"
import SegementedControls from "../components/SegementedControls/SegementedControls"
import mediaQuery, { greaterThen } from "../utils/media-query"

import InternshipInfoForm from "./Form/InternshipInfo"
import JobForm from "./Form/Job"
import GeneralForm from "./Form/GeneralInfo"
import LogoFacebook from "../images/LogoFacebook"
import imgCheck from "../images/icon-check.svg"

import { FormGroup, Label } from "./Form/style"
import styled from "@emotion/styled"
import PinPoint from "../images/VectorPinPoint"
import { useContactForm } from "../contexts/ContactForm"

const breakpoint = 768

const Arrow = styled.span`
  transition: transform ${transition.speed.default} ${transition.curve.default};
  display: inline;
  margin-left: 0.5ch;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const StyledButton = styled.button`
  appearance: none;
  border-radius: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  background-color: ${colors.offWhite};
  font-family: ${fonts.accent};
  line-height: 1em;
  color: ${colors.darkTeal};
  padding: ${fontSizes[3] / fontSizes[3]}em ${fontSizes[6] / fontSizes[3]}em
    ${fontSizes[2] / fontSizes[3]}em ${fontSizes[3] / fontSizes[3]}em;
  font-size: ${fontSizes[3] / fontSizes[1]}rem;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    ${Arrow} {
      transform: translateX(10px);
    }
  }
`

const StyledPinPoint = styled(PinPoint)`
  display: inline-block;
  height: 1em;
  vertical-align: middle;
  padding-right: ${fontSizes[0] / fontSizes[3]}em;
`

const ContactForm = () => {
  const { open, close, formType, setFormType, isOpen } = useContactForm()
  const [isSubmited, setIsSubmited] = useState(false)

  const handleFormTypeChange = (event) => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value

    setFormType(value)
  }

  const handleSubmited = () => setIsSubmited(true)

  const handleOpen = () => {
    if (isSubmited) setIsSubmited(true)

    open()
  }

  return (
    <div>
      <div
        css={css`
          display: inline-block;
          z-index: ${zIndices.sticky};
          position: fixed;
          top: 50vh;
          right: 0;
          transform: translateY(-100%) rotate(270deg) translateX(50%);
          transform-origin: bottom right;
          opacity: 0;
          pointer-events: none;

          ${greaterThen(1024)} {
            opacity: 1;
            pointer-events: auto;
          }
        `}
      >
        <div>
          <StyledButton onClick={handleOpen}>
            <StyledPinPoint color={colors.pastelRed} /> Demande d'informations{" "}
            <Arrow>→</Arrow>
          </StyledButton>
        </div>
      </div>

      <Modal isOpen={isOpen} handleClose={close}>
        <div
          css={css`
            padding: 0 ${fontSizes[1] / fontSizes[0]}em
              ${fontSizes[8] / fontSizes[0]}em;

            ${mediaQuery.greaterThen(768)} {
              padding: 0 ${fontSizes[8] / fontSizes[0]}em
                ${fontSizes[8] / fontSizes[0]}em;
            }
          `}
        >
          <p
            css={css`
              font-size: ${fontSizes[7] / fontSizes[1]}em;
              font-weight: 800;
              line-height: ${56 / fontSizes[7]};
              max-width: 320px;
              margin: 0;
              margin-bottom: ${fontSizes[0] / fontSizes[7]}em;
            `}
          >
            Formulaire de&nbsp;demande
          </p>

          <hr
            css={css`
              height: 2px;
              color: ${colors.offWhite};
              background: ${colors.offWhite};
              font-size: 0;
              border: 0;
            `}
          />

          {!isSubmited && (
            <>
              <FormGroup
                css={css`
                  grid-column: span 1;

                  ${mediaQuery.greaterThen(breakpoint)} {
                    grid-column: span 2;
                  }
                `}
              >
                <Label htmlFor="input-level">
                  Choisir un type de formulaire
                </Label>

                <SegementedControls
                  id="input-formType"
                  name="formType"
                  value={formType}
                  onChange={handleFormTypeChange}
                  breakpoint="650"
                  options={[
                    {
                      label: "Demande d'informations sur les stages",
                      value: "stage",
                    },
                    {
                      label: "Demande d’informations sur l’emploi",
                      value: "job",
                    },
                    {
                      label: "Demande d’informations générales",
                      value: "general",
                    },
                  ]}
                />
              </FormGroup>

              <InternshipInfoForm
                breakpoint={breakpoint}
                onSubmited={handleSubmited}
                hidden={formType !== "stage"}
              />

              <JobForm
                breakpoint={breakpoint}
                onSubmited={handleSubmited}
                hidden={formType !== "job"}
              />

              <GeneralForm
                breakpoint={breakpoint}
                onSubmited={handleSubmited}
                hidden={formType !== "general"}
              />
            </>
          )}

          {isSubmited && (
            <div
              css={css`
                display: flex;
                font-size: ${fontSizes[4] / fontSizes[1]}em;
                font-weight: bold;
                line-height: ${56 / fontSizes[7]};
                margin: ${fontSizes[7] / fontSizes[7]}em 0;
                align-items: flex-start;
              `}
            >
              <img
                css={css`
                  width: ${fontSizes[7] / fontSizes[4]}em;
                  padding-right: ${fontSizes[4] / fontSizes[4]}em;
                `}
                src={imgCheck}
                alt=""
              />

              <div>
                <p>
                  Merci, votre demande a bien été acheminée! Votre requête sera
                  traitée dans les meilleurs délais.
                </p>

                <p>
                  <a href="https://www.facebook.com/equipesarros/">
                    <LogoFacebook
                      css={{
                        fill: colors.offWhite,
                        width: "30px",
                        marginRight: "1em",
                      }}
                    />
                  </a>
                  Suivez-nous!
                </p>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ContactForm
