// vendors
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { colors, transition } from "../../styles/variables"

const Line = styled.span`
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;

  :after {
    content: "";
    position: absolute;
    height: 5px;
    width: 27px;
    background-color: ${colors.darkTeal};
    display: block;
    top: calc(50% - 2.75px);
    left: 50%;
  }

  ${(props) =>
    !props.isActive
      ? css`
          /* standby */
          transition: transform ${transition.speed.fast}
            ${transition.curve.default};
          :after {
            transition: transform ${transition.speed.fast}
              ${transition.curve.default} ${transition.speed.fast};
          }
        `
      : css`
          /* isActive */
          transition: transform ${transition.speed.fast}
            ${transition.curve.default} ${transition.speed.default};
          :after {
            transition: transform ${transition.speed.default}
              ${transition.curve.default};
          }
        `}
`

const BurgerButton = ({ children, isActive, ...props }) => (
  <button
    aria-label="Navigation"
    css={css`
      font-size: 0;
      appearance: none;
      border: 0;
      background: none;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0;
      margin-right: -6.5px;
      position: relative;
    `}
    {...props}
  >
    <Line
      isActive={isActive}
      css={
        !isActive
          ? css`
              /* standby */
              :after {
                transform: translateY(-10px) translateX(-50%);
              }
            `
          : css`
              /* isActive */
              transform: rotateZ(-45deg);
              :after {
                width: 35px;
                transform: translateY(0) translateX(-50%);
              }
            `
      }
    />

    <Line
      isActive={isActive}
      css={
        !isActive
          ? css`
              /* standby */
              :after {
                opacity: 1;
                transform: translateY(0) scaleX(1) translateX(-50%);
              }
            `
          : css`
              /* isActive */
              :after {
                opacity: 0;
                transform: translateY(0) scaleX(0) translateX(-50%);
              }
            `
      }
    />

    <Line
      isActive={isActive}
      css={
        !isActive
          ? css`
              /* standby */
              :after {
                transform: translateY(10px) translateX(-50%);
              }
            `
          : css`
              /* isActive */
              transform: rotateZ(45deg);
              :after {
                width: 35px;
                transform: translateY(0) translateX(-50%);
              }
            `
      }
    />
    {children}
  </button>
)

export default BurgerButton
