// vendors
import React from "react"

const LogoInstagram = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 87.5" {...props}>
      <path d="M43.75,7.88c11.68,0,13.07,0,17.68.26a24.37,24.37,0,0,1,8.12,1.5,13.67,13.67,0,0,1,5,3.28,13.67,13.67,0,0,1,3.28,5,24.37,24.37,0,0,1,1.5,8.12c.21,4.61.26,6,.26,17.68s-.05,13.07-.26,17.68a24.37,24.37,0,0,1-1.5,8.12,14.57,14.57,0,0,1-8.31,8.31,24.37,24.37,0,0,1-8.12,1.5c-4.61.21-6,.26-17.68.26s-13.07-.05-17.68-.26A24.37,24.37,0,0,1,18,77.86a13.67,13.67,0,0,1-5-3.28,13.67,13.67,0,0,1-3.28-5,24.37,24.37,0,0,1-1.5-8.12c-.21-4.61-.26-6-.26-17.68s0-13.07.26-17.68A24.37,24.37,0,0,1,9.64,18a13.67,13.67,0,0,1,3.28-5,13.67,13.67,0,0,1,5-3.28,24.37,24.37,0,0,1,8.12-1.5c4.61-.21,6-.26,17.68-.26m0-7.88c-11.88,0-13.37.05-18,.26a32.35,32.35,0,0,0-10.62,2,21.48,21.48,0,0,0-7.75,5,21.48,21.48,0,0,0-5,7.75,32.35,32.35,0,0,0-2,10.62C.05,30.38,0,31.87,0,43.75s.05,13.37.26,18a32.35,32.35,0,0,0,2,10.62,21.48,21.48,0,0,0,5,7.75,21.48,21.48,0,0,0,7.75,5,32.35,32.35,0,0,0,10.62,2c4.67.21,6.16.26,18,.26s13.37,0,18-.26a32.35,32.35,0,0,0,10.62-2A22.3,22.3,0,0,0,85.2,72.41a32.35,32.35,0,0,0,2-10.62c.21-4.67.26-6.16.26-18s0-13.37-.26-18a32.35,32.35,0,0,0-2-10.62,21.48,21.48,0,0,0-5-7.75,21.48,21.48,0,0,0-7.75-5,32.35,32.35,0,0,0-10.62-2C57.12.05,55.63,0,43.75,0Z" />
      <path d="M43.75,21.28A22.47,22.47,0,1,0,66.22,43.75,22.47,22.47,0,0,0,43.75,21.28Zm0,37.05A14.58,14.58,0,1,1,58.33,43.75,14.58,14.58,0,0,1,43.75,58.33Z" />
      <circle cx="67.1" cy="20.4" r="5.25" />
    </svg>
  )
}

export default LogoInstagram
