import { fontSizes } from "../variables"
import mediaQuery from "../../utils/media-query"

export const introParagraph = {
  fontSize: `${fontSizes[2] / fontSizes[0]}em`,
  lineHeight: `${fontSizes[3] / fontSizes[2]}`,
  fontWeight: "bold",
  [mediaQuery.greaterThen(fontSizes.breakpoint)]: {
    fontSize: `${fontSizes[5] / fontSizes[1]}em`,
  },
}

export default introParagraph
