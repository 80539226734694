export default [
  {
    label: "Bas-Saint-Laurent",
    value: "Bas-Saint-Laurent",
  },
  {
    label: "Saguenay-Lac-Saint-Jean",
    value: "Saguenay-Lac-Saint-Jean",
  },
  {
    label: "Mauricie-et-Centre-du-Québec",
    value: "Mauricie-et-Centre-du-Quebec",
  },
  {
    label: "Outaouais",
    value: "Outaouais",
  },
  {
    label: "Abitibi-Témiscamingue",
    value: "Abitibi-Temiscamingue",
  },
  {
    label: "Côte-Nord",
    value: "Cote-Nord",
  },
  {
    label: "Nord-du-Québec",
    value: "Nord-du-Quebec",
  },
  {
    label: "Gaspésie",
    value: "Gaspesie",
  },
  {
    label: "Îles-de-la-Madeleine",
    value: "Iles-de-la-Madeleine",
  },
  {
    label: "Laurentides",
    value: "Laurentides",
  },
  {
    label: "Nunavik",
    value: "Nunavik",
  },
  {
    label: "Terres-Cries-de-la-Baie-James",
    value: "Terres-Cries-de-la-Baie-James",
  },
]
