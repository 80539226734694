// vendors
import { css } from "@emotion/core"

// style varaibles
import { colors, fonts, lineHeights, fontSizes } from "../variables"

// mixins
import introParagraph from "../mixins/introParagraph"
import accentParagraph from "../mixins/accentParagraph"

// images
import pinPoint from "../../images/pinPoint-pastelRed.svg"
import { greaterThen } from "../../utils/media-query"
import { between } from "polished"

export default css`
  :root {
    font-size: ${fontSizes[0] / 16}em;
    line-height: ${22.5 / fontSizes[0]};

    ${greaterThen(fontSizes.breakpoint)} {
      font-size: ${fontSizes[1] / 16}em;
      line-height: ${28 / fontSizes[1]};
    }
  }

  html {
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: ${fonts.body};
    scroll-behavior: smooth;

    scroll-padding-top: ${between("85px", "144px", `1024px`, "1920px")};

    @media (prefers-reduced-motion: reduce) {
      scroll-behavior: auto;
    }
  }

  body {
    font-family: ${fonts.body};
    color: ${colors.text};
    font-weight: 500;
    font-kerning: normal;
    font-feature-settings: "kern", "liga", "clig", "calt", "lnum";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h4,
  h5,
  h6 {
    line-height: ${48 / 44.5}em;
  }

  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    font-family: ${fonts.accent};
    font-weight: 700;
  }

  h6 {
    font-weight: 500;
  }

  h1,
  .h1 {
    font-size: ${fontSizes[5] / fontSizes[0]}em;
    font-weight: 800;
    text-rendering: optimizeLegibility;
    line-height: ${40 / fontSizes[5]};

    @media (min-width: ${fontSizes.breakpoint}px) {
      font-size: ${fontSizes[8] / fontSizes[1]}em;
    }
  }

  h2,
  .h2 {
    font-size: ${fontSizes[6] / fontSizes[0]}em;
    font-weight: 800;
    text-rendering: optimizeLegibility;
    line-height: ${48 / fontSizes[6]};
    margin-top: 2em;
    word-break: break-all;
    word-break: break-word; /* safari fix */
    hyphens: auto;

    @media (min-width: ${fontSizes.breakpoint}px) {
      font-size: ${fontSizes[8] / fontSizes[1]}em;
    }
  }

  h3,
  .h3 {
    font-size: ${fontSizes[2] / fontSizes[0]}em;
    margin-top: ${fontSizes[8] / fontSizes[2]}em;
    margin-bottom: ${fontSizes[3] / fontSizes[2]}em;
    line-height: ${fontSizes[3] / fontSizes[2]};
    text-rendering: optimizeLegibility;
    word-break: break-all;
    word-break: break-word; /* safari fix */
    hyphens: auto;

    @media (min-width: ${fontSizes.breakpoint}px) {
      font-size: ${fontSizes[3] / fontSizes[1]}em;
    }
  }

  p {
    margin: 0 0 ${(lineHeights.body * fontSizes[1]) / fontSizes[1]}em 0;
    padding: 0;

    a {
      color: ${colors.accent};
    }
  }

  .intro {
    ${introParagraph}
  }

  .accent {
    ${accentParagraph}
  }

  .styled-content {
    a {
      color: ${colors.accent};
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 70px;
        margin-left: 0;
        margin-bottom: 0.25em;
        line-height: ${28 / fontSizes[1]};

        :before {
          left: 0;
          position: absolute;
          background-image: url(${pinPoint});
          background-repeat: no-repeat;
          background-size: 18px;
          background-position: center 20%;
          height: 32px;
          content: "";
          display: block;
          width: 60px;
        }
      }
    }

    ol {
      counter-reset: my-counter;
      list-style: none;
      padding-left: 0;

      li {
        padding-left: 70px;
        margin-left: 0;
        margin-bottom: 0.25em;
        line-height: ${28 / fontSizes[1]};

        :before {
          width: 60px;
          position: absolute;
          left: 0;
          text-align: right;
          font-family: ${fonts.accent};
          content: counter(my-counter) ". — ";
          counter-increment: my-counter;
          font-size: ${24 / 18}em;
        }
      }
    }
  }
`
